import Swiper, { Navigation, Manipulation } from "swiper";

window.ResizeObserver = undefined;

const sliderSteps = new Swiper('.slider-steps', {
  init: false,
  modules: [Navigation, Manipulation],
  slidesPerView: "auto",
  spaceBetween: 16,
  navigation: {
    nextEl: ".slider-steps-next",
    prevEl: ".slider-steps-prev",
  },
});

sliderSteps.on('init', function() {
  updatePrevButtonVisibility();
});

sliderSteps.init();

function updatePrevButtonVisibility() {
  const prevButton = document.querySelector('.slider-steps-prev');
  if (sliderSteps.isBeginning) {
    prevButton.classList.add('slider-steps-btn__disabled');
  } else {
    prevButton.classList.remove('slider-steps-btn__disabled');
  }
}

// Enable previous button when next button is clicked
sliderSteps.on('slideNextTransitionStart', function () {
  const prevButton = document.querySelector('.slider-steps-prev');
  prevButton.classList.remove('slider-steps-btn__disabled');
});

// Hide next button when slider reacher the end
sliderSteps.on('reachEnd', function () {
  const nextButton = document.querySelector('.slider-steps-next');
  nextButton.classList.add('slider-steps-btn__disabled');
});

// Hide previous button when slider reaches the start
sliderSteps.on('reachBeginning', function () {
  const prevButton = document.querySelector('.slider-steps-prev');
  const nextButton = document.querySelector('.slider-steps-next');
  prevButton.classList.add('slider-steps-btn__disabled');
  nextButton.classList.remove('slider-steps-btn__disabled');
});


function removeFirstSlide() {

  if (window.innerWidth > 1280) {
    sliderSteps.removeSlide(0); // Remove the first slide
  }
}

removeFirstSlide();

window.addEventListener('resize', removeFirstSlide);
