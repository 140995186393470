import Swiper, { Navigation } from "swiper";

window.ResizeObserver = undefined;

const sliderVideo = new Swiper('.slider-video', {
  init: false,
  modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 20,
  observer: true, // Enable observer
  observeParents: true, // Observe changes in parent elements
  navigation: {
    nextEl: ".slider-video-next",
    prevEl: ".slider-video-prev",
  },
  breakpoints: {
    1280: {
      spaceBetween: 74,
    }
  }
});

sliderVideo.on('init', function() {
 updatePrevButtonVisibility();
});

sliderVideo.init();

function updatePrevButtonVisibility() {
  const prevButton = document.querySelector('.slider-video-prev');
  if (sliderVideo.isBeginning) {
    prevButton.classList.add('slider-video-btn__disabled');
  } else {
    prevButton.classList.remove('slider-video-btn__disabled');
  }
}

// Enable previous button when next button is clicked
sliderVideo.on('slideNextTransitionStart', function () {
  const prevButton = document.querySelector('.slider-video-prev');
  prevButton.classList.remove('slider-video-btn__disabled');
});

// Hide next button when slider reacher the end
sliderVideo.on('reachEnd', function () {
  const nextButton = document.querySelector('.slider-video-next');
  nextButton.classList.add('slider-video-btn__disabled');
});

// Hide previous button when slider reaches the start
sliderVideo.on('reachBeginning', function () {
  const prevButton = document.querySelector('.slider-video-prev');
  const nextButton = document.querySelector('.slider-video-next');
  prevButton.classList.add('slider-video-btn__disabled');
  nextButton.classList.remove('slider-video-btn__disabled');
});