import $ from "jquery";

let videoSrc = "";
let videoModal = ".video-modal";
let videoPlayer = ".video-modal .player";
let videoClose = ".video-modal .close";

$('.btn-video').click(function(e) {
  e.preventDefault();
  videoSrc = $(this).data("src");
  $(videoModal).addClass('active');
  $(videoPlayer).attr('src', videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
});

$(videoClose).click(function(e) {
  $(videoModal).removeClass('active');
  $(videoPlayer).attr('src', videoSrc);
})



